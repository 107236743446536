import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Group } from "@mui/icons-material";

import Modal from "../../../../components/modal";
import {
  BalanceteData,
  BalanceteReturn,
  getBalancete,
  getDisciplineAndClasses,
  getMonthBalancete,
  getStudents,
  StudentList,
} from "../../../../../services";
import {
  BalanceteMonthItem,
  DisciplineWithClass,
  StudentData,
} from "../../../../../dtos";
import { useInstitution } from "../../../../context";

function createData(
  account: string,
  description: string,
  month1: string,
  month2: string,
  month3: string
) {
  return { account, description, month1, month2, month3 };
}

type monthOptions = 1 | 2 | 3;

interface MonthBalanceteType {
  month: monthOptions;
  accountId: number;
}

export default function SelectTable() {
  const { institutionData } = useInstitution();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingBalancete, setLoadingBalancete] = useState(false);
  const [loadingMonthBalancete, setLoadingMonthBalancete] = useState(false);
  const [loadingDisciplines, setLoadingDisciplines] = useState(true);
  const [balanceteData, setBalanceteData] = useState<BalanceteReturn>();
  const [monthBalanceteData, setMonthBalanceteData] =
    useState<BalanceteMonthItem[]>();
  const [classData, setClassData] = useState<DisciplineWithClass[]>();
  const [groupData, setGroupData] = useState<StudentList>();
  const [classId, setClassId] = useState<number>();
  const [groupId, setGroupId] = useState<number>();
  const [telegramId, setTelegramId] = useState<string>();
  const [month, setMonth] = useState<monthOptions>();

  const fetchBalancete = useCallback(async () => {
    setLoadingBalancete(true);
    if (classId === undefined || groupId === undefined) return;
    try {
      const data = await getBalancete({
        disciplineId: classId,
        groupId,
      });
      if (!data.balancete || !data.balancete.length) {
        setBalanceteData(undefined);
        return;
      }
      console.log(data.metrics);
      setBalanceteData(data);
    } catch (e) {
      setBalanceteData(undefined);
    } finally {
      setLoadingBalancete(false);
    }
  }, [classId, groupId, setBalanceteData, setLoadingBalancete]);

  const changeClass = async (item: DisciplineWithClass) => {
    try {
      const data = await getStudents({
        institutionId: institutionData.id,
        classId: item.classId,
        disciplineId: item.disciplineId,
      });
      if (data) {
        const checkByName = (a: StudentData, b: StudentData) => {
            const nameA = a.nome.toLowerCase();
            const nameB = b.nome.toLowerCase();
        
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }
        data.sort(checkByName);
      }
      setGroupData(data);
      setClassId(item.classId);
    } catch (e) {
      console.log(e);
      setClassId(undefined);
    }
  };

  const handleClickMonth = async (monthData: MonthBalanceteType) => {
    handleOpen();
    if (month === monthData.month) return;
    setLoadingMonthBalancete(true);
    try {
      const data = await getMonthBalancete({
        account: monthData.accountId,
        classId: classId || 0,
        month: monthData.month,
        telegramId: telegramId || "1",
      });
      console.log(data);
      setMonthBalanceteData(data);
    } catch (e) {
    } finally {
      setLoadingMonthBalancete(false);
      setMonth(monthData.month);
    }
  };

  const changeGroup = (item: StudentData) => {
    console.log(item);
    setGroupId(item.grupo);
    setTelegramId(item.telegram_id);
  };

  useEffect(() => {
    const callFetchInstitution = async () => {
      try {
        const data = await getDisciplineAndClasses({
          institution: institutionData.id,
        });
        setClassData(data);
      } catch (e) {
      } finally {
        setLoadingDisciplines(false);
      }
    };
    callFetchInstitution();
  }, []);

  const disenableButton = useMemo(() => {
    if (loadingBalancete) return true;
    return classId === undefined || groupId === undefined;
  }, [classId, loadingBalancete, groupId]);

  const makeCellStyle = (analytic: boolean, monthCondition: boolean) => {
    return analytic
      ? {
          backgroundColor: () => (monthCondition ? "green" : "red"),
        }
      : { fontWeight: "bold" };
  };

  const renderBalanceteTable = () => {
    if (classId === undefined || groupId === undefined || !balanceteData)
      return;
    if (loadingBalancete) return <CircularProgress sx={{ color: "#0D1B2A" }} />;
    if (balanceteData.balancete.length === 0) {
      return (
        <Typography>
          Ainda não foram submetidas tentativas de respostas
        </Typography>
      );
    }
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Conta</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center">Saldo Mês 1</TableCell>
              <TableCell align="center">Saldo Mês 2</TableCell>
              <TableCell align="center">Saldo Mês 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balanceteData.balancete.map((balanceteRow) => (
              <TableRow key={balanceteRow.conta_id}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: balanceteRow.anatica ? "normal" : "bold" }}
                >
                  {balanceteRow.codigo}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: balanceteRow.anatica ? "normal" : "bold" }}
                >
                  {balanceteRow.descricao}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() =>
                    handleClickMonth({
                      accountId: balanceteRow.conta_id,
                      month: 1,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    ...makeCellStyle(
                      balanceteRow.anatica,
                      balanceteRow.mes_1_correto
                    ),
                  }}
                >
                  {balanceteRow.mes_1}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() =>
                    handleClickMonth({
                      accountId: balanceteRow.conta_id,
                      month: 2,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    ...makeCellStyle(
                      balanceteRow.anatica,
                      balanceteRow.mes_2_correto
                    ),
                  }}
                >
                  {balanceteRow.mes_2}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() =>
                    handleClickMonth({
                      accountId: balanceteRow.conta_id,
                      month: 3,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    ...makeCellStyle(
                      balanceteRow.anatica,
                      balanceteRow.mes_3_correto
                    ),
                  }}
                >
                  {balanceteRow.mes_3}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="center">Acerto Analitica</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                {balanceteData.metrics.mes_1_total}%
              </TableCell>
              <TableCell align="center">
                {balanceteData.metrics.mes_2_total}%
              </TableCell>
              <TableCell align="center">
                {balanceteData.metrics.mes_3_total}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderBalanceteModalIn = () => {
    if (loadingMonthBalancete)
      return <CircularProgress sx={{ color: "#0D1B2A" }} />;
    if (!monthBalanceteData || balanceteData?.balancete.length === 0) {
      return (
        <Typography>
          Ainda não foram submetidas tentativas de respostas
        </Typography>
      );
    }

    return (
      <TableContainer>
        <Table sx={{ minWidth: 1200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Data</TableCell>
              <TableCell align="left">Contra Partida</TableCell>
              <TableCell align="left">Histórico</TableCell>
              <TableCell align="center">Saldo Anterior</TableCell>
              <TableCell align="center">Débitos</TableCell>
              <TableCell align="center">Créditos</TableCell>
              <TableCell align="center">Saldo Final</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthBalanceteData.map((balanceteRow) => (
              <TableRow key={balanceteRow.historico}>
                <TableCell align="center">{balanceteRow.data}</TableCell>
                <TableCell align="left">{balanceteRow.contrapartida}</TableCell>
                <TableCell align="left">{balanceteRow.historico}</TableCell>
                <TableCell align="center">
                  {balanceteRow.saldo_anterior}
                </TableCell>
                <TableCell align="center">{balanceteRow.debito}</TableCell>
                <TableCell align="center" onClick={handleOpen}>
                  {balanceteRow.credito}
                </TableCell>
                <TableCell align="center" onClick={handleOpen}>
                  {balanceteRow.saldo_final}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Paper sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Disciplinas cadastradas
      </Typography>
      <Box mb={2} display="flex">
        <Box sx={{ maxWidth: "318px", flexGrow: 1, marginRight: { md: 2 } }}>
          <TextField label="Disciplina" select fullWidth defaultValue="">
            {loadingDisciplines ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress sx={{ color: "#0D1B2A" }} />
              </Box>
            ) : classData && classData.length ? (
              classData.map((item) => (
                <MenuItem
                  key={item.disciplineId}
                  value={item.name}
                  onClick={async () => changeClass(item)}
                >
                  {item.name}
                </MenuItem>
              ))
            ) : null}
          </TextField>
        </Box>
        <Box sx={{ maxWidth: "318px", flexGrow: 1, marginRight: { md: 2 } }}>
          <TextField
            select
            fullWidth
            defaultValue=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 3 }}>
                  <Group />
                  <Typography sx={{ ml: 1 }}>Grupo</Typography>
                </InputAdornment>
              ),
            }}
          >
            {groupData && groupData.length ? (
              groupData.map((item) => (
                <MenuItem
                  key={item.grupo}
                  value={item.nome}
                  onClick={() => changeGroup(item)}
                >
                  {item.nome}
                </MenuItem>
              ))
            ) : (
              <Box></Box>
            )}
          </TextField>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#778DA9",
            ":hover": { backgroundColor: "#778DA9", opacity: 0.9 },
          }}
          onClick={fetchBalancete}
          disabled={disenableButton}
        >
          Gerar balancete
        </Button>
      </Box>
      {renderBalanceteTable()}
      <Modal open={open} handleClose={handleClose} handleOpen={handleOpen}>
        {renderBalanceteModalIn()}
      </Modal>
    </Paper>
  );
}
