import Api from "../../config";

export interface IAuthProps {
  username: string;
  password: string;
}

export interface IAuthResponse extends Response {
  refresh: string;
  access: string;
}

export const getToken = async (body: IAuthProps): Promise<IAuthResponse> => {
  try {
    const { data } = await Api.post<IAuthResponse>("user/token/", body);
    Api.defaults.headers.Authorization = `Bearer ${data.access}`;
    return data;
  } catch (error) {
    throw error;
  }
};

export const verifyToken = async (token: string): Promise<boolean> => {
  try {
    await Api.post("user/token/verify/", { token: token });
    Api.defaults.headers.Authorization = `Bearer ${token}`;
    return true;
  } catch (error) {
    throw error;
  }
};
