import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useInstitution } from "../../../presentation/context";

interface IProps {
  children: React.ReactNode;
}

const ProtectedRoute = (props: IProps) => {
  const navigate = useNavigate();
  const { institutionData } = useInstitution();

  const checkInstitution = useCallback(() => {
    if (!institutionData.id) {
      navigate("/select-institution");
    }
  }, [institutionData, navigate]);

  useEffect(() => {
    checkInstitution();
  }, [checkInstitution]);

  return <React.Fragment>{props.children}</React.Fragment>;
};
export default ProtectedRoute;
