import React from "react";

import { Typography } from "@mui/material";

import AsideLayout from "../../../components/Layout/aside";
import StudentsTable from "./students-table";

function EditClass() {
  return (
    <AsideLayout>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Visão geral
      </Typography>
      <StudentsTable />
    </AsideLayout>
  );
}

export default EditClass;
