import Api from "../../config";
import { StudentData, StudentAddData } from "../../dtos";

export type StudentList = StudentData[];

interface StudentProps {
  institutionId: number;
  disciplineId: number;
  classId: number;
}

interface StudentsAddProps extends StudentProps {
  payload: StudentAddData;
}

interface StudentsAddManyProps extends StudentProps {
  payload: StudentAddData[];
}

export const getStudents = async ({
  institutionId,
  classId,
  disciplineId,
}: StudentProps): Promise<StudentList> => {
  try {
    const { data } = await Api.get<StudentList>(
      `/usuarios/${institutionId}/${disciplineId}/${classId}/aluno/`
    );
    return data;
  } catch (error) {
    throw new Error("error");
  }
};

export const addOneStudent = async ({
  institutionId,
  classId,
  disciplineId,
  payload,
}: StudentsAddProps): Promise<StudentData> => {
  try {
    const { data } = await Api.post<StudentData>(
      `/usuarios/${institutionId}/${disciplineId}/${classId}/aluno/`,
      { ...payload, turma: classId }
    );
    return data;
  } catch (error) {
    throw new Error("error");
  }
};

export const addManyStudents = async ({
  institutionId,
  classId,
  disciplineId,
  payload,
}: StudentsAddManyProps): Promise<StudentData[]> => {
  try {
    const studentsData = payload.map((student) => ({
      ...student,
      turma: classId,
    }));
    console.log("studentsData", studentsData);
    const { data } = await Api.post<StudentData[]>(
      `/usuarios/${institutionId}/${disciplineId}/${classId}/aluno/criar_multiplos/`,
      studentsData
    );
    return data;
  } catch (error) {
    throw new Error("error");
  }
};
