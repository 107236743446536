import Api from "../../config";
import { InstitutionData } from "../../dtos";

export type InstitutionList = InstitutionData[];

export const getInstitution = async (): Promise<InstitutionList> => {
  try {
    const { data } = await Api.get<InstitutionList>("/usuarios/instituicao/");
    return data;
  } catch (error) {
    throw new Error("error");
  }
};
