import * as React from "react";
import Box from "@mui/material/Box";
import Alert, { AlertProps as UIAlertProps } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

interface AlertProps extends UIAlertProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export default function TransitionAlerts({
  children,
  open,
  handleClose,
  ...rest
}: AlertProps) {
  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        {...rest}
      >
        {children}
      </Alert>
    </Collapse>
  );
}
