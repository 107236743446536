import React from "react";

import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ClassIcon from "@mui/icons-material/Group";
import MenuIcon from "@mui/icons-material/Menu";
import { LogoSmall } from "../../../utils";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

const drawerWidth = 256;

const menuOptions = [
  {
    name: "Home",
    icon: <HomeIcon />,
    path: "/",
  },
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    name: "Atividades",
    icon: <FileCopyIcon />,
    path: "/activities",
  },
  {
    name: "Disciplina",
    icon: <ClassIcon />,
    path: "/class",
  },
];

function ClassList(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      {menuOptions.map((item, index) => (
        <ListItem
          key={item.name}
          disablePadding
          sx={{
            color: "#DDE2FF",
            borderLeft: "solid 3px #DDE2FF",
          }}
        >
          {location.pathname === item.path && (
            <Box
              sx={{
                position: "absolute",
                background: "#DDE2FF",
                opacity: 0.2,
                height: "100%",
                width: "100%",
              }}
            />
          )}
          <ListItemButton onClick={() => navigate(item.path)}>
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="left-menu"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1B263B",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          color="primary"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#1B263B",
            },
          }}
          open
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingY: 1,
            }}
          >
            <img alt="logo" src={LogoSmall} />
          </Box>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#F0F1F7",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default ClassList;
