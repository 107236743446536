import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";
import { verifyToken as apiVerifyToken, getToken } from "../../services/auth";
import { IUserLoginData } from "../../dtos/interfaces";
import { StorageService } from "../../services";

interface Props {
  children: React.ReactNode;
}

interface TokenData {
  token?: string;
  refresh?: string;
}

interface IAuthContext {
  tokenData?: TokenData;
  isAuthenticate: boolean;
  login: (data: IUserLoginData) => Promise<void>;
  logout: () => void;
  verifyToken: () => Promise<boolean | undefined>;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: Props) => {
  const storageService = useMemo(() => new StorageService(), []);
  const [tokenData, setTokenData] = useState<TokenData>();
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const logout = useCallback(() => {
    setTokenData({} as TokenData);
    setIsAuthenticate(false);
    storageService.removeItem("secret");
    storageService.removeItem("refresh");
    storageService.removeItem("institutionData");
  }, [storageService]);

  const login = useCallback(
    async (data: IUserLoginData) => {
      try {
        const { access, refresh } = await getToken(data);
        setTokenData({ token: access, refresh });
        setIsAuthenticate(true);
        storageService.saveOrUpdateItem("secret", access || "");
        storageService.saveOrUpdateItem("refresh", refresh || "");
      } catch (e) {
        logout();
        throw e;
      }
    },
    [logout, storageService]
  );

  const verifyToken = useCallback(async () => {
    const value = window.localStorage.getItem("secret");
    if (!value) {
      logout();
      return false;
    }
    try {
      await apiVerifyToken(value);
      setIsAuthenticate(true);
      return true;
    } catch (e) {
      logout();
      throw e;
    }
  }, [logout]);

  const value = useMemo(
    () => ({
      tokenData,
      isAuthenticate,
      login,
      logout,
      verifyToken,
    }),
    [tokenData, isAuthenticate, login, logout, verifyToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
