import React from "react";

function Signup() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Signup</p>
      </header>
    </div>
  );
}

export default Signup;
