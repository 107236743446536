import Api from "../../config";
import { BalanceteItem, BalanceteMonthItem } from "../../dtos";
import { mockAccount } from "./mockAccount";
import { mockMonth } from "./mockMonth";

export type BalanceteData = BalanceteItem[];

interface BalanceteResponse {
  balancete: BalanceteItem[];
}

interface MonthMetric {
  mes_1_total: string;
  mes_2_total: string;
  mes_3_total: string;
}

export interface BalanceteReturn {
  balancete: BalanceteItem[];
  metrics: MonthMetric;
}

interface BalanceteProps {
  disciplineId: number;
  groupId: number;
}

interface MonthBalanceteProps {
  telegramId: string;
  month: number;
  classId: number;
  account: number;
}

export const getBalancete = async ({
  disciplineId,
  groupId,
}: BalanceteProps): Promise<BalanceteReturn> => {
  try {
    const { data } = await Api.get<BalanceteResponse>(
      `/contabil/balancete/${disciplineId}/${groupId}`
    );
    let trueData = data;
    if (process.env.NODE_ENV === "development") {
      trueData = mockAccount;
    }
    const metrics = addMetricToBalancete(trueData);
    return { balancete: trueData.balancete, metrics };
  } catch (error) {
    throw new Error("error");
  }
};

const addMetricToBalancete = (data: BalanceteResponse): MonthMetric => {
  let mes_1_sum = 0;
  let mes_2_sum = 0;
  let mes_3_sum = 0;
  let repetition = 0;
  data.balancete.forEach((item) => {
    if (item.anatica) {
      repetition++;
      if (item.mes_1_correto) mes_1_sum++;
      if (item.mes_2_correto) mes_2_sum++;
      if (item.mes_3_correto) mes_3_sum++;
    }
  });

  const percentage = {
    mes_1_total: (100 * mes_1_sum) / repetition,
    mes_2_total: (100 * mes_2_sum) / repetition,
    mes_3_total: (100 * mes_3_sum) / repetition,
  };

  return {
    mes_1_total: percentage.mes_1_total.toFixed(2),
    mes_2_total: percentage.mes_2_total.toFixed(2),
    mes_3_total: percentage.mes_3_total.toFixed(2),
  };
};

export const getMonthBalancete = async ({
  month,
  account,
  classId,
  telegramId,
}: MonthBalanceteProps): Promise<BalanceteMonthItem[]> => {
  try {
    const { data } = await Api.get<BalanceteMonthItem[]>(
      `/contabil/balancete/razao/${telegramId}/${classId}/${month}/${account}`
    );
    if (process.env.NODE_ENV === "development") {
      return mockMonth;
    }
    return data;
  } catch (error) {
    throw new Error("error");
  }
};
