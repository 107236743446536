import { createContext, useContext, useState } from "react";
import { InstitutionData } from "../../dtos";
import { StorageService } from "../../services";

interface Props {
  children: React.ReactNode;
}

interface IInstitutionContext {
  institutionData: InstitutionData;
  setInstitutionData: (data: InstitutionData) => void;
}

export const InstitutionContext = createContext<IInstitutionContext>(
  {} as IInstitutionContext
);

export const InstitutionProvider = ({ children }: Props) => {
  const storageService = new StorageService();
  const [institutionState, setInstitutionState] = useState<InstitutionData>(
    storageService.getJsonItem<InstitutionData>("institutionData")
  );

  const setInstitutionData = (data: InstitutionData) => {
    setInstitutionState(data);
    storageService.saveOrUpdateItem("institutionData", data);
  };

  return (
    <InstitutionContext.Provider
      value={{
        institutionData: institutionState,
        setInstitutionData,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};

export const useInstitution = () => {
  return useContext(InstitutionContext);
};
