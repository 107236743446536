import React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Search, Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Layout: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl variant="outlined" sx={{ flexGrow: 1 }}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          inputProps={{
            "aria-label": "weight",
          }}
          placeholder="Buscar disciplina"
        />
      </FormControl>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#778DA9", marginLeft: { md: 2 } }}
        endIcon={<Add />}
        onClick={() => navigate("/class/add")}
      >
        Criar disciplina
      </Button>
    </Box>
  );
};

export default Layout;
