import Api from "../../config";
import { ClassData } from "../../dtos";

export type ClassList = ClassData[];

interface DisciplineProps {
  institution: number;
  disciplineId: string;
}

export const getClass = async ({
  disciplineId,
  institution,
}: DisciplineProps): Promise<ClassList> => {
  try {
    const { data } = await Api.get<ClassList>(
      `/usuarios/${institution}/${disciplineId}/turma/`
    );
    return data;
  } catch (error) {
    throw new Error("error");
  }
};
