import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Popover,
  Link,
  CircularProgress,
  Button,
} from "@mui/material";
import SearchForm from "../../../../components/search-form";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { DisciplineList, getDiscipline } from "../../../../../services";
import { useInstitution, useDiscipline } from "../../../../context";
import { DisciplineData } from "../../../../../dtos";

function createData(
  classCode: string,
  students: string,
  period: string,
  institution: string
) {
  return { classCode, students, period, institution };
}

const rows = [
  createData("DPR3101", "32", "2022.2", "UFRN"),
  createData("DPR3102", "42", "2022.2", "UNP"),
  createData("DPR3103", "12", "2022.2", "UFERSA"),
  createData("DPR3104", "23", "2022.2", "UFRN"),
  createData("DPR3105", "44", "2022.2", "UFRN"),
];

export default function BasicTable() {
  const { institutionData } = useInstitution();
  const { setDisciplineData } = useDiscipline();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [disciplineList, setDisciplineList] = useState<DisciplineList>();
  const [isLoading, setLoading] = useState(true);
  const [selectedDisciplineData, setSelectedDisciplineData] =
    useState<DisciplineData>();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    disciplineData: DisciplineData
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDisciplineData(disciplineData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchDiscipline = async () => {
    const data = await getDiscipline({ institution: institutionData.id });
    setDisciplineList(data);
  };

  useEffect(() => {
    const callFetchInstitution = async () => {
      try {
        await fetchDiscipline();
      } catch (e) {
        window.location.reload();
      } finally {
        setLoading(false);
      }
    };
    callFetchInstitution();
  }, []);

  const selectDiscipline = (selectedDiscipline: DisciplineData) => {
    setDisciplineData(selectedDiscipline);
    navigate(`/class/${selectedDiscipline.id}`);
  };

  const navigateToSelectedDiscipline = () => {
    selectedDisciplineData && navigate(`/class/${selectedDisciplineData.id}`);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (isLoading) return <CircularProgress sx={{ color: "#0D1B2A" }} />;

  return (
    <Paper sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Disciplinas cadastradas
      </Typography>
      <Box mb={2}>
        <SearchForm />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Disciplina</TableCell>
              <TableCell align="center">Alunos</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disciplineList &&
              disciplineList.map((discipline) => (
                <TableRow key={discipline.codigo}>
                  <TableCell
                    align="center"
                    onClick={() => selectDiscipline(discipline)}
                  >
                    <Link component="div">{discipline.codigo}</Link>
                  </TableCell>
                  <TableCell align="center">{discipline.nome}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={(e) => handleClick(e, discipline)}
                      sx={{ width: "100%", height: "100%", borderRadius: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Button
            onClick={navigateToSelectedDiscipline}
            variant="text"
            sx={{
              px: 4,
              py: 2,
              textTransform: "capitalize",
              color: "#000000",
            }}
          >
            Visualizar
          </Button>
          <Button
            variant="text"
            sx={{
              px: 4,
              py: 2,
              textTransform: "capitalize",
              color: "#000000",
            }}
          >
            Apagar
          </Button>
        </Box>
      </Popover>
    </Paper>
  );
}
