import React from "react";

import { Typography, Paper, Button, TextField, Box } from "@mui/material";

import AsideLayout from "../../components/Layout/aside";

function AddClass() {
  return (
    <AsideLayout>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Visão geral
      </Typography>
      <Paper sx={{ padding: 3, maxWidth: "649px" }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Cadastrar uma disciplina
        </Typography>
        <TextField label="Nome da disciplina" fullWidth sx={{ mb: 2 }} />
        <TextField label="Instituição" fullWidth sx={{ mb: 2 }} />
        <TextField label="Período letivo" fullWidth sx={{ mb: 2 }} />
        <Box display="flex">
          <Button
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#778DA9" }}
          >
            Gerar balancete
          </Button>
        </Box>
      </Paper>
    </AsideLayout>
  );
}

export default AddClass;
