import React from "react";
import { Box, Container } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

function CenteredLayout({ children }: Props) {
  return (
    <Box
      sx={{
        backgroundColor: "#1B263B",
        display: "flex",
        flex: 1,
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Container>
    </Box>
  );
}

export default CenteredLayout;
