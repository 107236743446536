import React, { useState, useMemo } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { Link as NavigatorLink, useNavigate } from "react-router-dom";
import { Logo } from "../../../utils";
import { useAuth } from "../../context";
import ButtonLoading from "../../components/button-loading";
import CenteredLayout from "../../components/Layout/center-layout";

interface ErrorApi {
  response: {
    data: { detail: string };
    status: number;
  };
}

interface IUserLoginData {
  username: string;
  password: string;
}

type FormNames = "username" | "password";

function Signin() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<IUserLoginData>({
    username: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isDisable = useMemo(
    () => !userData.username || !userData.password,
    [userData]
  );

  const changeFormData = (name: FormNames, value: string) => {
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      await login(userData);
      navigate("/select-institution");
    } catch (e) {
      const error = e as ErrorApi;
      console.log("teste", e);
      console.log(error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CenteredLayout>
      <Card
        sx={{
          maxWidth: 427,
          display: "block",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0D1B2A",
            display: "flex",
            justifyContent: "center",
            paddingY: 2,
          }}
        >
          <img alt="logo" src={Logo} />
        </Box>
        <CardContent
          sx={{ paddingX: 5, paddingY: 7, backgroundColor: "#E0E1DD" }}
        >
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            onChange={(e) => changeFormData("username", e.target.value)}
            fullWidth
            sx={{ backgroundColor: "#FCFDFE", marginBottom: 2 }}
          />

          <FormControl
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: "#FCFDFE", marginBottom: 1 }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => changeFormData("password", e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 5 }}>
            <NavigatorLink to="/" style={{ textDecoration: "none" }}>
              <Link sx={{ textDecoration: "none" }} component="span">
                <Typography component="span">Esqueceu a senha?</Typography>
              </Link>
            </NavigatorLink>
          </Box>

          <Box display="flex">
            <ButtonLoading
              variant="contained"
              sx={{
                flexGrow: 1,
                backgroundColor: "#0D1B2A",
                "&:hover": { backgroundColor: "#0D1B2A", opacity: 0.9 },
              }}
              onClick={() => submit()}
              disabled={isDisable}
              isLoading={isLoading}
            >
              ACESSAR PLATAFORMA
            </ButtonLoading>
          </Box>
        </CardContent>
      </Card>
    </CenteredLayout>
  );
}

export default Signin;
