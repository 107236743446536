import React from "react";
import Routes from "./presentation/routes";
import {
  AuthProvider,
  InstitutionProvider,
  DisciplineProvider,
} from "./presentation/context";

function App() {
  return (
    <AuthProvider>
      <InstitutionProvider>
        <DisciplineProvider>
          <Routes />
        </DisciplineProvider>
      </InstitutionProvider>
    </AuthProvider>
  );
}

export default App;
