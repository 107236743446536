import React, { useState, useEffect, useMemo, useRef } from "react";
import Papa from "papaparse";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  Check,
  QueryBuilderRounded,
  Edit,
  Add,
  TextSnippetRounded,
} from "@mui/icons-material";

import { useParams, useNavigate } from "react-router-dom";

import Modal from "../../../../components/modal";
import Alert from "../../../../components/alert";
import {
  StudentList,
  getStudents,
  addOneStudent,
  addManyStudents,
} from "../../../../../services";
import { useInstitution, useDiscipline } from "../../../../context";
import ButtonLoading from "../../../../components/button-loading";

interface NewStudentData {
  nome: string;
  matricula: string;
  email: string;
}

type FormNames = keyof NewStudentData;

interface FileResults {
  data: NewStudentData[];
}

export default function StudentsTable() {
  const { institutionData } = useInstitution();
  const { disciplineData } = useDiscipline();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { classId } = useParams();

  const [open, setOpen] = useState(false);
  const [studentList, setStudentList] = useState<StudentList>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAddStudent, setIsLoadingAddStudent] = useState(false);
  const [isLoadingAddManyStudent, setIsLoadingAddManyStudent] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [studentData, setStudentData] = useState<NewStudentData>({
    email: "",
    matricula: "",
    nome: "",
  });
  const [csvData, setCsvData] = useState<NewStudentData[]>();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenFeedback = () => setOpenFeedback(true);
  const handleCloseFeedback = () => setOpenFeedback(false);

  const changeFormData = (name: FormNames, value: string) => {
    setStudentData((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchStudent = async () => {
    if (!classId) {
      navigate(-1);
      return;
    }

    const data = await getStudents({
      institutionId: institutionData.id,
      classId: +classId,
      disciplineId: disciplineData.id,
    });
    setStudentList(data);
  };

  useEffect(() => {
    const callFetchInstitution = async () => {
      try {
        await fetchStudent();
      } catch (e) {
        window.location.reload();
      } finally {
        setIsLoading(false);
      }
    };
    callFetchInstitution();
  }, []);

  const isDisable = useMemo(
    () => !Object.values(studentData).every((data) => data),
    [studentData]
  );

  const clearStudentData = () => {
    setStudentData({
      email: "",
      matricula: "",
      nome: "",
    });
  };

  const addStudent = async () => {
    setIsLoadingAddStudent(true);

    if (!classId) {
      navigate(-1);
      return;
    }

    try {
      const newStudent = await addOneStudent({
        institutionId: institutionData.id,
        classId: +classId,
        disciplineId: disciplineData.id,
        payload: studentData,
      });

      setStudentList((prev) => {
        if (!prev) return [newStudent];
        return [...prev, newStudent];
      });

      handleClose();

      clearStudentData();
      handleOpenFeedback();
      setFeedbackMessage("Aluno adicionado com sucesso");
    } catch (e) {
    } finally {
      setIsLoadingAddStudent(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: function (results: FileResults) {
        console.log(results.data);
        setCsvData(results.data);
      },
    });
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddManyStudents = async () => {
    if (!classId) {
      navigate(-1);
      return;
    }

    if (!csvData) {
      return;
    }
    setIsLoadingAddManyStudent(true);

    try {
      const newStudents = await addManyStudents({
        institutionId: institutionData.id,
        classId: +classId,
        disciplineId: disciplineData.id,
        payload: csvData,
      });
      setStudentList((prev) => {
        if (!prev) return [...newStudents];
        return [...prev, ...newStudents];
      });
      setCsvData(undefined);
      handleOpenFeedback();
      setFeedbackMessage("Alunos adicionados com sucesso");
    } catch (e) {
    } finally {
      setIsLoadingAddManyStudent(false);
    }
  };

  if (isLoading) return <CircularProgress sx={{ color: "#0D1B2A" }} />;

  return (
    <Paper sx={{ padding: 3 }}>
      <Alert open={openFeedback} handleClose={handleCloseFeedback}>
        {feedbackMessage}
      </Alert>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Turma {classId}
      </Typography>
      {csvData && (
        <Box mb={2}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonLoading
              variant="contained"
              sx={{ backgroundColor: "#778DA9", marginLeft: { md: 2 } }}
              onClick={handleAddManyStudents}
              endIcon={!isLoadingAddManyStudent && <Check />}
              isLoading={isLoadingAddManyStudent}
            >
              Confirmar Adição
            </ButtonLoading>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Matricula</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((student) => (
                  <TableRow key={student.matricula}>
                    <TableCell align="center">{student.nome}</TableCell>
                    <TableCell align="center">{student.matricula}</TableCell>
                    <TableCell align="center">{student.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <TableContainer>
        <Box display="flex" justifyContent="end">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#778DA9", marginLeft: { md: 2 } }}
            onClick={handleOpen}
            endIcon={<Add />}
          >
            Adicionar Aluno
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#778DA9", marginLeft: { md: 2 } }}
            endIcon={<TextSnippetRounded />}
            onClick={handleFileUpload}
          >
            Importar CSV
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            hidden
            accept=".csv"
            onChange={handleFileChange}
          />
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Matricula</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Confirmação (telegram)</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList &&
              studentList.map((student) => (
                <TableRow key={student.id}>
                  <TableCell align="center">{student.nome}</TableCell>
                  <TableCell align="center">{student.matricula}</TableCell>
                  <TableCell align="center">{student.email}</TableCell>
                  <TableCell align="center">
                    {student.telegram_id ? (
                      <Check color="success" />
                    ) : (
                      <QueryBuilderRounded color="error" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} handleClose={handleClose} handleOpen={handleOpen}>
        <TextField
          id="nome"
          label="Nome"
          variant="outlined"
          onChange={(e) => changeFormData("nome", e.target.value)}
          fullWidth
          sx={{ backgroundColor: "#FCFDFE", marginBottom: 2 }}
        />
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          onChange={(e) => changeFormData("email", e.target.value)}
          fullWidth
          sx={{ backgroundColor: "#FCFDFE", marginBottom: 2 }}
        />
        <TextField
          id="matricula"
          label="Matricula"
          variant="outlined"
          onChange={(e) => changeFormData("matricula", e.target.value)}
          fullWidth
          sx={{ backgroundColor: "#FCFDFE", marginBottom: 2 }}
        />
        <ButtonLoading
          variant="contained"
          sx={{
            flexGrow: 1,
            backgroundColor: "#0D1B2A",
            "&:hover": { backgroundColor: "#0D1B2A", opacity: 0.9 },
          }}
          onClick={() => addStudent()}
          disabled={isDisable}
          isLoading={isLoadingAddStudent}
          fullWidth
        >
          Cadastrar
        </ButtonLoading>
      </Modal>
    </Paper>
  );
}
