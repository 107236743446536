import React, { useEffect, useState } from "react";

import Signin from "../pages/externals/signin";
import Signup from "../pages/externals/signup";
import SelectInstitution from "../pages/externals/select-institution";
import Home from "../pages/internals/home";
import DisciplineList from "../pages/internals/discipline-list";
import ClassList from "../pages/internals/class-list";
import Dashboard from "../pages/internals/dashboard";
import Activities from "../pages/internals/activities";
import AddClass from "../pages/internals/add-discipline";
import EditClass from "../pages/internals/edit-class";
import ProtectedRoute from "./ProtectedRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "../../presentation/context";
import CircularProgress from "@mui/material/CircularProgress";
import CenteredLayout from "../components/Layout/center-layout";

function AppRoutes() {
  const { verifyToken, isAuthenticate } = useAuth();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const callVerifyToken = async () => {
      try {
        await verifyToken();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    callVerifyToken();
  }, [verifyToken]);

  if (isAuthenticate)
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="select-institution"
            element={
              <ProtectedRoute>
                <SelectInstitution />
              </ProtectedRoute>
            }
          />
          <Route
            path="activities"
            element={
              <ProtectedRoute>
                <Activities />
              </ProtectedRoute>
            }
          />
          <Route
            path="class"
            element={
              <ProtectedRoute>
                <DisciplineList />
              </ProtectedRoute>
            }
          />
          <Route
            path="class/add"
            element={
              <ProtectedRoute>
                <AddClass />
              </ProtectedRoute>
            }
          />
          <Route
            path="class/:disciplineId"
            element={
              <ProtectedRoute>
                <ClassList />
              </ProtectedRoute>
            }
          />
          <Route
            path="class/:disciplineId/:classId"
            element={
              <ProtectedRoute>
                <EditClass />
              </ProtectedRoute>
            }
          />
          <Route
            path="signin"
            element={
              <ProtectedRoute>
                <Signin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    );

  if (isLoading)
    return (
      <CenteredLayout>
        <CircularProgress sx={{ color: "#fff" }} />
      </CenteredLayout>
    );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="signup" element={<Signup />} />
        <Route path="signin" element={<Signin />} />
        <Route path="*" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
