import React from "react";

import { Typography } from "@mui/material";

import AsideLayout from "../../../components/Layout/aside";
import SelectTable from "./SelectTable";

function ClassList() {
  return (
    <AsideLayout>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Turmas cadastradas
      </Typography>
      <SelectTable />
    </AsideLayout>
  );
}

export default ClassList;
