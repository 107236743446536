import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../utils";
import { getInstitution } from "../../../services";
import CenteredLayout from "../../components/Layout/center-layout";
import { InstitutionList } from "../../../services";
import { useInstitution } from "../../context";

function SelectInstitution() {
  const navigate = useNavigate();
  const { setInstitutionData: setInstitutionDataContext } = useInstitution();
  const [institutionSelectedId, setInstitutionSelectedId] = useState<string>();
  const [institutionData, setInstitutionData] = useState<InstitutionList>();
  const [isLoading, setLoading] = useState(true);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setInstitutionSelectedId(nextView);
  };

  const setInstitution = () => {
    if (!institutionSelectedId) return;

    const finded = institutionData?.find(
      (institution) => `${institution.id}` === institutionSelectedId
    );

    if (!finded) return;

    setInstitutionDataContext(finded);
    navigate("/class");
  };

  const fetchInstitution = async () => {
    const data = await getInstitution();
    setInstitutionData(data);
  };

  useEffect(() => {
    const callFetchInstitution = async () => {
      try {
        await fetchInstitution();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    callFetchInstitution();
  }, []);

  if (isLoading)
    return (
      <CenteredLayout>
        <CircularProgress sx={{ color: "#fff" }} />
      </CenteredLayout>
    );

  return (
    <Box
      sx={{
        backgroundColor: "#1B263B",
        display: "flex",
        flex: 1,
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            maxWidth: "100%",
            minWidth: 455,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#0D1B2A",
              display: "flex",
              justifyContent: "center",
              paddingY: 2,
            }}
          >
            <img alt="logo" src={Logo} />
          </Box>
          <CardContent
            sx={{ paddingX: 5, paddingY: 7, backgroundColor: "#E0E1DD" }}
          >
            <ToggleButtonGroup
              orientation="vertical"
              value={institutionSelectedId}
              exclusive
              onChange={handleChange}
              fullWidth
            >
              {institutionData &&
                institutionData.map((institution) => (
                  <ToggleButton
                    value={`${institution.id}`}
                    aria-label={`${institution.id}`}
                  >
                    {institution.nome}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0D1B2A", flexGrow: 1 }}
                onClick={() => setInstitution()}
                disabled={!institutionSelectedId}
              >
                Selecionar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default SelectInstitution;
