import React from "react";
import { Button, ButtonProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface IProps extends ButtonProps {
  isLoading?: boolean;
}

const ButtonLoading = ({ isLoading, children, ...rest }: IProps) => {
  return (
    <Button {...rest}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  );
};

export default ButtonLoading;
