import { createContext, useContext, useState } from "react";
import { DisciplineData } from "../../dtos";

interface Props {
  children: React.ReactNode;
}

interface DisciplineSelected {
  disciplineData: DisciplineData;
  setDisciplineData: (data: DisciplineData) => void;
}

export const DisciplineContext = createContext<DisciplineSelected>(
  {} as DisciplineSelected
);

export const DisciplineProvider = ({ children }: Props) => {
  const [disciplineData, setDisciplineData] = useState<DisciplineData>(
    {} as DisciplineData
  );

  return (
    <DisciplineContext.Provider
      value={{
        disciplineData,
        setDisciplineData,
      }}
    >
      {children}
    </DisciplineContext.Provider>
  );
};

export const useDiscipline = () => {
  return useContext(DisciplineContext);
};
