import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Popover,
  Link,
  CircularProgress,
} from "@mui/material";
import SearchForm from "../../../../components/search-form";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link as RouterLink } from "react-router-dom";
import { ClassList, getClass } from "../../../../../services";
import { useInstitution, useDiscipline } from "../../../../context";

function createData(
  classCode: string,
  students: string,
  period: string,
  institution: string
) {
  return { classCode, students, period, institution };
}

const rows = [
  createData("DPR3101", "32", "2022.2", "UFRN"),
  createData("DPR3102", "42", "2022.2", "UNP"),
  createData("DPR3103", "12", "2022.2", "UFERSA"),
  createData("DPR3104", "23", "2022.2", "UFRN"),
  createData("DPR3105", "44", "2022.2", "UFRN"),
];

export default function BasicTable() {
  const { institutionData } = useInstitution();
  const { disciplineData } = useDiscipline();
  const { disciplineId } = useParams<{ disciplineId: string }>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [disciplineList, setDisciplineList] = useState<ClassList>();
  const [isLoading, setLoading] = useState(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchDiscipline = async () => {
    const data = await getClass({
      institution: institutionData.id,
      disciplineId: disciplineId || "",
    });
    setDisciplineList(data);
  };

  useEffect(() => {
    const callFetchInstitution = async () => {
      try {
        await fetchDiscipline();
      } catch (e) {
        window.location.reload();
      } finally {
        setLoading(false);
      }
    };
    callFetchInstitution();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (isLoading) return <CircularProgress sx={{ color: "#0D1B2A" }} />;

  return (
    <Paper sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Disciplina {disciplineData.codigo}
      </Typography>
      <Box mb={2}>
        <SearchForm />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Turma</TableCell>
              <TableCell align="center">Alunos</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disciplineList &&
              disciplineList.map((discipline) => (
                <TableRow key={discipline.codigo}>
                  <TableCell align="center">
                    <RouterLink to={`${discipline.id}`}>
                      <Link component="div">{discipline.codigo}</Link>
                    </RouterLink>
                  </TableCell>
                  <TableCell align="center">30</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={handleClick}
                      sx={{ width: "100%", height: "100%", borderRadius: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <>
          <Typography sx={{ px: 4, py: 2 }}>Visualizar</Typography>
          <Typography sx={{ px: 4, py: 2 }}>Apagar</Typography>
        </>
      </Popover>
    </Paper>
  );
}
