import Api from "../../config";
import {
  DisciplineData,
  DisciplineClassData,
  DisciplineWithClass,
} from "../../dtos";

export type DisciplineList = DisciplineData[];
export type DisciplineWithClassList = DisciplineClassData[];

interface DisciplineProps {
  institution: number;
}

export const getDiscipline = async ({
  institution,
}: DisciplineProps): Promise<DisciplineList> => {
  try {
    const { data } = await Api.get<DisciplineList>(
      `/usuarios/${institution}/disciplina/`
    );
    return data;
  } catch (error) {
    throw new Error("error");
  }
};

export const getDisciplineAndClasses = async ({
  institution,
}: DisciplineProps): Promise<DisciplineWithClass[]> => {
  try {
    const { data } = await Api.get<DisciplineWithClassList>(
      `/usuarios/disciplinas-e-turmas/${institution}`
    );
    return spreadClass(data);
  } catch (error) {
    throw new Error("error");
  }
};

const spreadClass = (data: DisciplineWithClassList) => {
  const inter: DisciplineWithClass[] = [];
  data.forEach((discipline) => {
    return discipline.turmas.forEach((turma) => {
      inter.push({
        disciplineId: discipline.id,
        classId: turma.id,
        name: `${discipline.nome} - ${discipline.codigo}.${turma.codigo}`,
      });
    });
  });
  return inter;
};
