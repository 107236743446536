import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  maxHeight: "90%",
  overflow: "auto",
};

interface IModalProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  children: React.ReactNode;
}

export default function BasicModal({
  open,
  handleClose,
  handleOpen,
  children,
}: IModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
}
